body {
    font-family: 'Avenir Medium', Sans-serif;
}
a {
    cursor: pointer;
}
.App {
    text-align: center;
}
.pulseLoader {
    width: 4em;
    height: 4em;
    border-radius: 4em;
    background-color: #535353;
    outline: 1px solid transparent;
    animation: pulseanim 1.2s ease-in-out infinite;
    margin: 20% auto;
}
.right-panel .search {
    background: #fff;
    margin-left: auto;
    margin-top: 21px;
    width: 400px;
}
.right-panel .search input {
    padding: 8px;
}
.right-panel .breadcrumb sub {
    margin-top: 22px;
    margin-left: 4px;
    display: inline-block;
}
.right-panel .breadcrumb a sub {
    color: rgb(202, 221, 239);
    cursor: pointer;
    margin-right: 5px;
}
@keyframes pulseanim {
    0% {
        transform: scale(0);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

a:link {
    text-decoration: none;
}
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
a:active {
    text-decoration: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    align-items: center;
    background-color: #fff;
    border: 0;
    box-shadow: 0 2px 4px -1px #4f5b687d;
    color: #535353;
    display: flex;
    font-size: calc(10px + 2vmin);
    justify-content: center;
    height: 60px;
    padding: 0;
    position: relative;
    z-index: 1;
}
.App-header .home-icon {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    cursor: pointer;
}
.App-header .home-icon:hover {
    background: #e8f1f9;
}
.App-header .home-icon img {
    border-radius: 5px;
    height: 40px;
    margin-top: 5px;
    width: 40px;
}
.right-panel .Error {
    text-align: center;
    margin-top: 20%;
}
.App-body {
    background-color: white;
    min-height: calc(100% - 100px);
    font-size: calc(10px + 2vmin);
    color: #535353;
    display: flex;
    align-items: stretch;
    text-align: left;
    overflow: auto;
}

.App-body .ProcedureSection {
    padding: 20px;
    background: white;
    border: 1px;
    border-style: solid;
    border-radius: 4px 4px 0px 0px;
    border-color: #caddef;
    width: calc(100% - 80px);
    margin: 20px;
    overflow: visible;
    height: max-content;
}
.App-Body h1 {
    font-weight: 800;
    font-size: 14px;
    margin: 20px 0px;
}
.App-body h2 {
    border: #535353 0px 0px 2px 0px;
    margin: 20px;
    font-weight: 800;
    font-size: 14px;
    color: rgb(126, 139, 158);
}

.App-body .ProcedureSection h2 {
    margin: 0px;
}

.App-body .ProcedureSection ul {
    margin: 0px;
    padding: 0px;
}
.App-Body .ProcedureSection .success li:before {
    content: '\2713';
    display: inline-block;
    color: green;
    padding: 0 6px 0 0;
}
.App-body .ProcedureSection li {
    list-style: none;
    background: white;
    list-style: none;
    padding: 5px;
    border-radius: 15px;
    display: flex;
    text-align: left;
    flex-wrap: wrap;
}
.App-body .ProcedureSection .mentions__suggestions__list li {
    border-radius: 0px;
}
.App-body .ProcedureSection li p {
    flex-grow: 1;
    margin: 0px;
    flex-basis: min-content;
    flex: 1 0 100%;
}
.App-body .ProcedureSection li sub {
    display: block;
    font-size: 12px;
    color: #98a8b7;
    flex-basis: 100%;
}
.App-body .ProcedureSection li sub .missing {
    color: #ed325a;
    display: inline-block;
    margin-right: 10px;
}
.App-body .ProcedureSection li sub .added {
    color: #4dd387;
    display: inline-block;
}
.App-body .ProcedureSection li {
    display: flex;
    width: 100%;
    position: relative;
}
.App-body .ProcedureSection li button,
.App-body .ProcedureSection .tile .info-row button {
    margin: -20px 10px 0px 0px;
    background: white;
    border-radius: 4px;
    border: 1px solid rgb(182, 193, 215);
    text-align: center;
    justify-content: center;
    padding: 5px;
    min-width: 30px;
    color: black;
    text-transform: none;
    font-family: avenir;
    font-weight: 400;
    float: right;
    top: 50%;
    right: 0px;
    position: absolute;
}
.App-body .ProcedureSection li .count {
    margin-top: 20px;
    margin-left: -17px;
}
.App-body button.finish {
    background: #4dd387;
}
.App-body .ProcedureSection p {
    font-size: 16px;
}
.App-body .ProcedureSection .tile {
    margin: 20px 20px 20px 0px;
    position: relative;
}
.App-body .tile a {
    display: inline-block;
}
.App-body .ViewFlows .loader {
    background: #fff;
    border-radius: 10px;
    min-height: 130px;
    margin-top: calc(50vh - 210px);
    padding: 40px;
    width: 200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.App-body .ViewFlows .loader img {
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.App-body .ProcedureSection .pulseLoader {
    height: 2em;
    width: 2em;
    border-radius: 2em;
    margin: 0px auto;
}
.App-link {
    color: #61dafb;
}

.left-panel {
    display: block;
    float: left;
    width: 300px;
    height: 100vh;
    background-color: rgb(248, 251, 255);
    color: #535353;
    border-right: 1px solid #caddef;
    overflow: auto;
    min-width: 300px;
}
.left-panel .delete {
    position: absolute;
    right: 15px;
    top: 20px;
}
.left-panel .delete svg {
    width: 15px;
    height: 15px;
    border-radius: 30px;
    padding: 10px;
}
.left-panel .delete svg:hover {
    background: #e8f1f9;
}
.left-panel li svg {
    width: 15px;
    height: 15px;
}
.left-panel .search {
    margin: 20px 0px 20px 20px;
    width: calc(100% - 40px);
    background: white;
}
.left-panel .search .MuiInput-root,
.left-panel .search input {
    width: 100%;
}
.right-panel {
    display: block;
    float: left;
    width: calc(100% - 200px);
    height: 100vh;
    overflow: auto;
    background: #f9fafc;
}
.flowtype {
    height: 30px;
    margin-right: 5px;
    width: 30px;
    margin-top: 5px;
}
.flowtype svg {
    height: 100%;
    width: 100%;
    min-width: 30px;
}
.flowtype.training {
    color: #4dd387;
}
.flowtype.task {
    color: #e560b8;
}
.flowtype.guide {
    color: #794bff;
}
.row {
    display: flex;
    margin: 0px 20px;
}
.right-panel .row:first-child {
    margin-top: 10px;
}
.header-row {
    margin: 20px 0px 10px 0px;
}

.row .column {
    display: flex;
    width: 50%;
}

.row .green {
    color: #4dd387;
    text-align: right;
    font-size: 14px;
}
.row .red {
    color: red;
    text-align: right;
    font-size: 14px;
}
.row sub {
    display: block;
    margin-left: auto;
}
.row .header-buttons {
    height: auto;
    margin: 15px 0px 0 auto;
}
.row .header-buttons button {
    border: none;
    height: 40px;
    width: 40px;
    margin: 0px;
    background: transparent;
    cursor: pointer;
    align-items: center;
    text-align: center;
}
.row .header-buttons button.selected {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #b6c1d7;
}
.row .header-buttons button:hover {
    background: #e8f1f9;
}
.row .header-buttons button svg {
    height: 1.2m;
    width: 1.2em;
    margin: 0px auto;
}
.right-panel .MuiToggleButtonGroup-grouped {
    margin: 0px !important; 
}
.right-panel button  {
    border-color: #b6c1d7;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 7px;
    height: 35px;
    min-width: 36px;
    margin: 5px;
    cursor: pointer;
    color: black;
    display: inline-flex;
}
.right-panel button.stop {
    background: #50596c;
    color: white;
    margin: 5px 13px;
}
.right-panel button svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.right-panel button.icon-only svg {
    margin-right: 0px;
}
.pill-select {
    margin-top: 10px !important;
    margin-right: 10px !important;
}
.pill-select .MuiInputBase-root,
.pill-select {
    border-radius: 50px;
    width: 150px !important;
    font-size: 14px;
    background: #edf1f7;
}
.pill-select .MuiSelect-select {
    padding: 5px 15px;
    text-align: left;
}
.pill-select .selected {
    background: #f1edfa;
}
.pill-select .selected fieldset {
    border-color: #794bff !important;
    border-style: solid !important;
}
.pill-select .MuiInputLabel-formControl {
    top: -7px;
    font-size: 14px;
}
.pill-select .MuiFormControl-root {
    width: 140px;
}
.pill-select fieldset {
    border-style: none !important;
    border-width: 1px !important;
}
.pill-select-menu ul li:hover {
    background: #edf1f7 !important;
}
.pill-select-menu ul .Mui-selected,
.pill-select-menu ul li {
    background: none !important;
}
.pill-select-menu {
    font-family: avenir !important;
}
.tile .tile-buttons button {
    display: none;
    background: white;
}
.tile:hover .tile-buttons button {
    display: inline-flex;
}
.tile .tile-buttons button:hover,
.App-body .ProcedureSection li button:hover {
    background: #e8f1f9;
}
.App-body .ProcedureSection li button.selected {
    background: #4f5b68;
    color: white;
    margin-left: 16px;
}
.right-panel button.big-button {
    background: #794bff;
    box-shadow: none;
    color: #fff;
    padding: 18px 30px;
    float: right;
    margin-right: 18px;
}
.right-panel button.big-button.disabled {
    background: #4f5b68;
}
.right-panel .MuiTabs-flexContainer button {
    border: none;
    background: none;
}
.right-panel .MuiCheckbox-root.center {
    width: auto;
    margin: 0px auto;
}
.right-panel .MuiTableRow-root .MuiChip-filled {
    margin: 1px;
}
.right-panel .MuiTableRow-root sub {
    display: block;
}
.right-panel .MuiTableRow-root sub svg {
    margin-right: 3px;
}
.right-panel th {
    text-transform: uppercase;
    font-weight: bold;
    color: #7d889f;
}
.right-panel .MuiPaper-root {
    width: calc(100% - 40px);
    margin: 20px auto 70px auto;
}
.right-panel .MuiTableBody-root .no-change {
    color: #b6c1d7;
    display: inline-block;
    margin-right: 5px;
    padding: 0px 11px;
}
.right-panel .MuiTableBody-root .missing {
    color: #e3273e;
    display: inline-block;
    margin: 0px 5px;
    font-size: 20px;
}
.right-panel .MuiTableBody-root .added {
    color: #168547;
    display: inline-block;
    margin: 0px 5px;
    font-size: 20px;
    margin-right: 5px;
}
.right-panel .MuiTableBody-root .added .count,
.right-panel .MuiTableBody-root .missing .count {
    border-radius: 20px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    height: 20px;
    text-align: center;
    min-width: 14px;
    padding: 0px 3px;
}
.right-panel .MuiTableBody-root .added .count {
    background: #168547;
}
.right-panel .MuiTableBody-root .missing .count {
    background: #e3273e;
}
.left-panel ul {
    max-height: calc(100% - 175px);
    overflow: auto;
    padding: 0;
    display: block;
    margin: 0px;
}
.left-panel li {
    cursor: pointer;
    display: block;
    list-style: none;
    padding: 20px 50px 20px 20px;
    position: relative;
    text-decoration: none;
    width: calc(100% - 70px);
    font-size: 20px;
}
.right-panel .bottom-action-bar {
    background: #3d3f47;
    bottom: 0;
    color: #fff;
    height: 60px;
    position: absolute;
    width: calc(100% - 300px);
    font-size: 15px;
    display: flex;
    align-items: center;
    right: 0px;
}
.right-panel .bottom-action-bar .info {
    vertical-align: middle;
    margin-left: 10px;
}
.right-panel .bottom-action-bar .info .line {
    background: #fff;
    height: 40px;
    margin: -10px 10px -14px 17px;
    width: 1px;
    display: inline-block;
}
.right-panel .bottom-action-bar .info svg {
    stroke: #fff;
    background: #fff;
    border-radius: 15px;
    color: #fff;
    height: 12px;
    margin: -4px 5px;
    width: 12px;
    padding: 4px;
    fill: black;
}
.right-panel .bottom-action-bar a {
    display: inline;
    cursor: pointer;
}
.right-panel .bottom-action-bar button {
    background: #794bff;
    color: #fff;
    margin-left: auto;
    padding: 7px 15px;
    margin-right: 20px;
    border: none;
}
.left-panel li.selected {
    background: #f1edfa;
}
.left-panel li.library {
    color: #145fc8;
}
.left-panel li.connection {
    color: orange;
}
.left-panel .panel-header {
    display: inline-block;
    width: calc(100% - 130px);
    margin: 10px;
    font-family: Avenir, Sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
}
.add-new {
    background: #4dd387;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    font-size: 16px;
    margin: 25px 15px 0px 15px;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    width: calc(100% - 70px);
}
.HomePage {
    text-align: center;
    background: rgb(249, 250, 252);
}
.tile {
    width: 240px;
    margin: 20px;
    border-color: rgb(202, 221, 239);
    border-style: solid;
    border-width: 1px;
    background: white;
    display: inline-block;
    cursor: pointer;
}
.tile img {
    width: 100%;
    height: max-content;
}
.tile img.icon,
.tile .flowtype {
    width: 50px;
    height: 50px;
    display: flex;
}
.tile .tile-buttons {
    position: absolute;
    text-align: right;
    top: 0;
    width: 100%;
}

.tile .coverimage {
    width: 100%;
    height: 140px;
    background: rgb(249, 250, 252);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.tile .info {
    font-size: 14px;
    padding: 10px;
    font-family: 'Avenir';
    font-size: 14px;
    font-style: normal;
    display: flex;
    flex-direction: column;
}
.tile .info .title {
    font-family: Avenir Heavy;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-align: left;
}
.tile .count {
    margin: 0px 5px 0px 0px;
}
.row-buttons {
    width: 150px;
}
.row-text-crop {
    max-width: 250px;
    min-width: 50px;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
}
.count svg {
    color: #caddef;
    height: 20px;
    width: 20px;
    margin-top: -1px;
    vertical-align: middle;
}
.count {
    color: #caddef;
    display: inline;
}
.count div {
    display: inline;
    height: 100%;
    margin-top: calc(50% - 7px);
}
.info .info-row {
    width: 100%;
    display: flex;
    height: 25px;
}

.documentation {
    margin-top: 50px;
}
.documentation h1 {
    margin: 10px 0px;
    font-size: 24px;
}
.documentation h2 {
    border: #535353 0px 0px 2px 0px;
    margin: 20px;
    font-weight: 800;
    font-size: 18px;
    color: rgb(126, 139, 158);
}
.documentation h3 {
    border: #535353 0px 0px 2px 0px;
    margin: 20px;
    font-weight: 800;
    font-size: 14px;
    color: rgb(126, 139, 158);
}
.documentation code {
    background: #42b6e71a;
    border-radius: 10px;
    padding: 10px;
    width: calc(100% - 20px);
    font-size: 16px;
    display: block;
}
.documentation ul li {
    font-size: 16px;
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
