.login-form {
    width: 350px;
    height: 350px;
    margin-top: calc(50% - 500px);
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.login-form .button {
    width: 100%;
    margin: 10px 8px;
    display: block;
}
.right-panel .applyAll {
    font-size: 14px;
    float: right;
    margin-top: -10px;
}